<template>
    <div class="content">
        <div class="header">
            <div class="data-title">
                <div class="cot"></div>
                <h3>消息详情</h3>
            </div>
            <User></User>
        </div>
        <div class="cont-box">
            <h3>{{text.tipTitle}}</h3>
            <p v-html="text.tipContent"></p>
        </div>
    </div>
</template>
<script>
import User from "./../../components/user.vue"
export default {
    components: {
        User
    },
    data(){
        return{
            text:{},
        }
    },
    created() {
        this.PostTextNewsDetails()
    },
    methods: {
        PostTextNewsDetails() {
            let id = this.$route.query.id
            let userID = localStorage.getItem('userID')
            this.$api.TextNewsDetails({
                tipId: id,
                userId:userID
            }).then(res=>{
                this.text = res.data
            })
        }
    }
}
</script>
<style scoped>
h3 {
    font-size: 1em;
    color: #000000;
}

.content {
    min-height: 100vh;
    background-color: #eee;

}

.header {
    width: 90%;
    margin: 0 auto;
    padding-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cont-box {
    width: 90%;
    margin: 0 auto;
    margin-top: 2em;
    padding: 2em;
    background-color: #fff;
    box-sizing: border-box;
}

.cont-box h3 {
    font-size: 2em;
}
.cont-box p{
    margin-top: 1em;
    line-height: 32px;
    text-indent: 2em;
    font-size: 1em;

}
</style>